import { Routes } from '@angular/router';

import { PageNotFoundComponent } from '@ygm/common/shared/components/page-not-found/page-not-found.component';

import { activeCampaignGuard } from './core/guards/active-campaign.guard';
import { authorizedGuard } from './core/guards/authorized.guard';
import { createdCampaignGuard } from './core/guards/created-campaign.guard';
import { doneCampaignGuard } from './core/guards/done-campaign.guard';
import { emptyRenewalContractsGuard } from './core/guards/empty-contracts.guard';
import { previewGuard } from './core/guards/preview.guard';
import { authorizedAndRenewedCampaignGuard } from './core/guards/renewed-campaign.guard';
import { subdomainGuard } from './core/guards/subdomain.guard';
import { unauthorizedGuard } from './core/guards/unauthorized.guard';
import { userWithDetailsGuard } from './core/guards/user-with-details.guard';
import { userWithoutDetailsGuard } from './core/guards/user-without-details.guard';
import { webRoutePaths } from './features/shared/web-route-paths';
import { notCloakedUserCampaignGuard } from './core/guards/not-cloaked-user-campaign.guard';

/**
 * Routes object are described based on the created by `buildRoutePaths` object.
 * In some cases, when we define children for dynamic parameters, we must initialize
 * this parameter with an empty string, as in the example below.
 * This behavior is allowed in routing modules.
 * @example
 * ```ts
 * const routePaths = buildRoutePaths({
 *   dashboard: {
 *     path: 'dashboard',
 *     children: {
 *       auth: { path: 'auth' },
 *       users: {
 *         path: ':id',
 *         children: {
 *           edit: { path: 'edit' },
 *         },
 *       },
 *     },
 *   },
 * } as const);
 *
 * const routes: Routes = [
 *   {
 *     path: routePaths.dashboard.path,
 *     children: [
 *       {
 *         path: routePaths.dashboard.children.auth.path,
 *         component: AuthComponent,
 *       },
 *       {
 *         path: routePaths.dashboard.children.users.path,
 *         children: [
 *           {
 *             path: routePaths.dashboard.children.users.children({ id: '' }).edit.path,
 *             component: EditUserComponent,
 *           },
 *         ],
 *       },
 *     ],
 *   };
 * ];
 * ```
 */
export const appRoutes: Routes = [
	{
		path: '',
		redirectTo: webRoutePaths.landing.path,
		pathMatch: 'full',
	},
	{
		path: webRoutePaths.preview.path,
		loadComponent: () => import('./features/site-preview/site-preview.component').then(c => c.SitePreviewComponent),
		canActivate: [previewGuard(false), subdomainGuard],
		title: webRoutePaths.preview.title,
	},
	{
		path: '',
		loadComponent: () => import('./layouts/authorized-layout/authorized-layout.component').then(c => c.AuthorizedLayoutComponent),
		canActivate: [
			unauthorizedGuard,
			userWithoutDetailsGuard,
			subdomainGuard,
			authorizedAndRenewedCampaignGuard,
			doneCampaignGuard,
			createdCampaignGuard,
			notCloakedUserCampaignGuard,
			previewGuard(true),
		],
		children: [
			{
				path: webRoutePaths.dashboard.path,
				loadChildren: () =>
					import('./features/dashboard/dashboard.routes').then(r => r.dashboardRoutes),
				title: webRoutePaths.dashboard.title,
			},
			{
				path: webRoutePaths.leaderboard.path,
				loadComponent: () =>
					import('./features/leaderboard/leaderboard.component').then(c => c.LeaderboardComponent),
			},
			{
				path: webRoutePaths.remainingSponsorships.path,
				loadChildren: () =>
					import('./features/remaining-sponsorships/remaining-sponsorships.routes').then(r => r.remainingSponsorshipsRoutes),
			},
			{
				path: webRoutePaths.resources.path,
				loadChildren: () =>
					import('./features/resources/resources.routes').then(r => r.resourcesRoutes),
			},
		],
	},
	{
		path: '',
		loadComponent: () => import('./layouts/unauthorized-layout/unauthorized-layout.component').then(c => c.UnauthorizedLayoutComponent),
		canActivate: [subdomainGuard, previewGuard(true)],
		children: [
			{
				path: webRoutePaths.auth.path,
				loadChildren: () =>
					import('./features/auth/auth.routes').then(r => r.authRoutes),
				canActivate: [authorizedGuard],
			},
			{
				path: webRoutePaths.landing.path,
				loadChildren: () =>
					import('./features/landing-page/landing.routes').then(r => r.landingRoutes),
				canActivate: [authorizedAndRenewedCampaignGuard],
			},
			{
				path: webRoutePaths.businessInformation.path,
				loadChildren: () =>
					import('./features/business-information/business-information.routes').then(r => r.businessInformationRoutes),
				canActivate: [unauthorizedGuard, userWithDetailsGuard, doneCampaignGuard],
			},
			{
				path: webRoutePaths.marketingProducts.path,
				loadChildren: () =>
					import('./features/marketing-products/marketing-products.routes').then(r => r.marketingProductsRoutes),
				canActivate: [
					authorizedAndRenewedCampaignGuard,
					createdCampaignGuard,
					notCloakedUserCampaignGuard,
					doneCampaignGuard,
				],
			},
			{
				path: webRoutePaths.purchasingMembers.path,
				title: webRoutePaths.purchasingMembers.title,
				loadComponent: () =>
					import('./features/purchasing-members/purchasing-members.component').then(c => c.PurchasingMembersComponent),
				canActivate: [authorizedAndRenewedCampaignGuard, createdCampaignGuard, notCloakedUserCampaignGuard, doneCampaignGuard],
			},
			{
				path: webRoutePaths.contractSign.path,
				loadComponent: () =>
					import('./features/contracts/contract-sign/contract-sign.component').then(c => c.ContractSignComponent),
			},
			{
				path: webRoutePaths.renewalContracts.path,
				loadChildren: () =>
					import('./features/renewal-contracts/renewal-contracts.routes').then(r => r.renewalContractsRoutes),
				canActivate: [
					unauthorizedGuard,
					userWithoutDetailsGuard,
					activeCampaignGuard,
					createdCampaignGuard,
					notCloakedUserCampaignGuard,
					emptyRenewalContractsGuard,
				],
			},
			{
				path: webRoutePaths.contracts.path,
				loadChildren: () =>
					import('./features/contracts/contracts.routes').then(r => r.contractsRoutes),
				canActivate: [
					unauthorizedGuard,
					userWithoutDetailsGuard,
					createdCampaignGuard,
					notCloakedUserCampaignGuard,
					doneCampaignGuard,
				],
			},
			{
				path: webRoutePaths.publicInvoice.path,
				loadChildren: () =>
					import('./features/public-invoice/public-invoice.routes').then(r => r.publicInvoiceRoutes),
			},
			{
				path: webRoutePaths.profile.path,
				loadChildren: () =>
					import('./features/profile/profile.routes').then(r => r.profileRoutes),
				canActivate: [unauthorizedGuard],
			},
		],
	},
	{
		path: webRoutePaths.auth.path,
		canActivate: [previewGuard(true)],
		children: [
			{
				path: webRoutePaths.auth.children.confirmPassword.path,
				loadComponent: () =>
					import('./features/auth/confirm-password/confirm-password.component').then(c => c.ConfirmPasswordComponent),
			},
		],
	},
	{
		path: '**',
		pathMatch: 'full',
		component: PageNotFoundComponent,
	},
];
